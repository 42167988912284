import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/user/:id',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/promocoding',
    name: 'Promocoding',
    component: () => import(/* webpackChunkName: "promocoding" */ '../views/Promocoding.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: () => import(/* webpackChunkName: "recipes" */ '../views/Recipes.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('onkitch-admin-tool-token');
  const loggedIn = token && token !== 'null';
  if (to.path !== '/login' && !loggedIn) {
    next({ path: '/login' });
  } else if (to.path === '/login' && loggedIn) {
    next({ path: '/' });
  } else {
    next();
  }
});

export default router;
