<template>
  <v-app>
    <v-main>
      <v-col class="app-container mx-auto">
        <v-row class="my-4 mx-2 mx-sm-0">
          <v-btn v-if="$route.path !== '/' && $route.path !== '/login'"
                 :text="$vuetify.breakpoint.smAndUp"
                 :icon="!$vuetify.breakpoint.smAndUp"
                 @click="$router.push('/')"
                 :width="$vuetify.breakpoint.smAndUp ? 110 : 36"
                 class="mt-sm-1"
          >
            <v-icon class="mr-2">mdi-arrow-left</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">Back</span>
          </v-btn>
          <div v-if="$route.path === '/'"
               :style="$vuetify.breakpoint.smAndUp ? 'width: 110px;' : 'width: 36px;'"></div>
          <v-spacer/>
          <h1 class="text-center text-sm-h4 text-h6">Onkitch Admin Tool</h1>
          <v-spacer/>
          <v-btn v-if="$route.path !== '/login'"
                 :text="$vuetify.breakpoint.smAndUp"
                 :icon="!$vuetify.breakpoint.smAndUp"
                 @click="$api.main.logout()"
                 :width="$vuetify.breakpoint.smAndUp ? 110 : 36"
                 class="mt-sm-1"
          >
            <span v-if="$vuetify.breakpoint.smAndUp">Exit</span>
            <v-icon class="ml-2">mdi-logout</v-icon>
          </v-btn>
        </v-row>
        <router-view class="px-0 pt-4"/>
      </v-col>
    </v-main>

    <v-snackbar v-if="snackbar" v-model="snackbar.model" :timeout="snackbar.timeout"
                :color="snackbar.color || 'info'" :bottom="true">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon @click="snackbar.model = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="confirmDialog.model" :max-width="confirmDialog.width" persistent>
      <v-card>
        <v-card-title>
          <span>{{ confirmDialog.title }}</span>
          <v-spacer/>
          <v-btn icon @click="confirmDialog.model = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-html="confirmDialog.text"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="confirmDialog.cancel()">
            Cancel
          </v-btn>
          <v-btn color="primary darken-1" text @click="confirmDialog.ok()">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  computed: {
    snackbar() {
      return this.$api.main.getSnack();
    },
    confirmDialog() {
      return this.$api.main.getConfirmDialog();
    },
  },
};
</script>

<style scoped>
.app-container {
  min-width: 300px;
}
</style>
