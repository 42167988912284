import AppStore from './AppStore';

export default {
  install(Vue /* , options */) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$store = {
      AppStore,
    };
  },
  AppStore,
};
