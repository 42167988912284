import OnkitchAdminApi from './OnkitchAdminApi';

export default {
  install(Vue, options) {
    const main = new OnkitchAdminApi(Vue, options.router, options.store);
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$api = {
      main,
    };
  },
};
