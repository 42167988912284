<template>
  <div class="mx-auto" style="max-width: 400px;">
<!--    <span class="title">Recipes</span>-->
    <v-btn depressed block x-large
           @click="open"
           :loading="$store.AppStore.isOpenRecipesLoading"
    >Open recipes</v-btn>
  </div>
</template>

<script>
export default {
  name: 'OpenRecipes',
  methods: {
    open(...args) {
      return this.$api.main.openRecipes(...args);
    },
  },
};
</script>

<style scoped>

</style>
