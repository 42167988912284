import Vue from 'vue';

export default Vue.observable({
  // app store
  helloMessage: 'Hello!',
  snackbar: {
    model: false,
    color: 'info',
    timeout: 3000,
    text: 'Ok',
  },
  confirmDialog: {
    model: false,
    title: 'Confirm your actions',
    text: 'Are you sure?',
    width: 400,
    cancel: null,
    ok: null,
  },
  // router store
  // auth store: token
  authLogin: '',
  authPassword: '',
  // ui store
  currentMail: '',
  // static data store
  menus: [],
  // data store (cache)
  currentUser: null,
  currentMenus: null,
  currentSpecialMenus: null,
  // loading store
  isCurrentUserLoading: false,
  isCurrentMenusLoading: false,
  isCurrentSpecialMenusLoading: false,

  isAuthLoading: false,
  isFindUserLoading: false,
  isOpenReportLoading: false,
  isOpenPromocodingLoading: false,
  isOpenRecipesLoading: false,
  isUpdatePasswordLoading: false,
  isAddMenuLoading: false,
  isAddSpecialMenuLoading: false,
  isRemoveMenuLoading: false,
  isRemoveSpecialMenuLoading: false,

  addMenuRequestData: {},
  addSpecialMenuRequestData: {},
  removeMenuRequestData: {},
  removeSpecialMenuRequestData: {},

  // report
  reportIngredients: null,
  reportRecipes: null,
  reportPromocodes: null,
  reportUsersPayments: null,

  // promocoding
  promocodes: null,

  // all
  recipesSets: null,
  recipesData: null,
});
