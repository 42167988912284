<template>
  <div class="mx-auto" style="max-width: 400px;">
    <span class="title">Find user by email</span>
    <v-text-field v-model="currentMail" label="Email" outlined dense hide-details class="mt-2"/>
    <v-btn depressed block
           :disabled="isDisabledFindUser"
           @click="findUser"
           :loading="$store.AppStore.isFindUserLoading"
           class="mt-2"
    >Find user</v-btn>
  </div>
</template>

<script>
export default {
  name: 'OpenUser',
  computed: {
    currentMail: {
      get() {
        return this.$api.main.getCurrentMail();
      },
      set(...args) {
        return this.$api.main.setCurrentMail(...args);
      },
    },
    isDisabledFindUser() {
      return this.$api.main.isDisabledFindUser();
    },
  },
  methods: {
    findUser(...args) {
      return this.$api.main.findUser(...args);
    },
  },
};
</script>

<style scoped>

</style>
