import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

import api from './api';
import store from './store';

Vue.use(store);
Vue.use(api, {
  router,
  store: {
    app: store.AppStore,
  },
});

Vue.config.productionTip = false;

const app = new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

app.$axios.interceptors.response.use((response) => response, async (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('onkitch-admin-tool-token');
    await app.$router.push('/login');
    app.$api.main.snackError('You must be logged in');
  }
  return error;
});
