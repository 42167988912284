<template>
  <div class="mx-auto" style="max-width: 400px;">
<!--    <span class="title">Promocoding</span>-->
    <v-btn depressed block x-large
           @click="openPromocoding"
           :loading="$store.AppStore.isOpenPromocodingLoading"
    >Open promocoding</v-btn>
  </div>
</template>

<script>
export default {
  name: 'OpenPromocoding',
  methods: {
    openPromocoding(...args) {
      return this.$api.main.openPromocoding(...args);
    },
  },
};
</script>

<style scoped>

</style>
