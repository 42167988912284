export default class SampleApi1 {
  constructor(Vue, router, store) {
    this.Vue = Vue;
    this.router = router;
    this.store = store;
    this.baseUrl = process.env.VUE_APP_API_URL;
  }

  async sendRequest(url, params, method = 'get') {
    try {
      let isTimeout = false;
      setTimeout(() => {
        isTimeout = true;
      }, 400);
      const response = await this.Vue.prototype.$axios(`${this.baseUrl}/${url}`, {
        method,
        headers: {
          authorization: localStorage.getItem('onkitch-admin-tool-token'),
        },
        [method === 'get' ? 'params' : 'data']: params,
      });
      if (!isTimeout) {
        await new Promise((r) => {
          const interval = setInterval(() => {
            if (isTimeout) {
              clearInterval(interval);
              r();
            }
          }, 50);
        });
      }
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  get2DigitsFormat(x) {
    return (`0${x}`).slice(-2);
  }

  formatDate(s) {
    const d = new Date(s);
    return `${this.get2DigitsFormat(d.getDate())}.${this.get2DigitsFormat(d.getMonth() + 1)}.${d.getFullYear()}`;
  }

  getHelloMessage() {
    return this.store.app.helloMessage;
  }

  getSnack() {
    return this.store.app.snackbar;
  }

  getConfirmDialog() {
    return this.store.app.confirmDialog;
  }

  snack(text, color = 'info') {
    this.store.app.snackbar.text = text;
    this.store.app.snackbar.color = color;
    this.store.app.snackbar.model = true;
  }

  snackInfo(text) {
    this.snack(text || 'Ok');
  }

  snackError(text) {
    this.snack(text || 'Error', 'error');
  }

  snackSuccess(text) {
    this.snack(text || 'Success', 'success');
  }

  openConfirmDialog({
    text, title, cancel, ok,
  }) {
    if (title) {
      this.store.app.confirmDialog.title = title;
    }
    if (text) {
      this.store.app.confirmDialog.text = text;
    }
    this.store.app.confirmDialog.ok = () => {
      if (ok) {
        ok();
      }
      this.store.app.confirmDialog.model = false;
    };
    this.store.app.confirmDialog.cancel = () => {
      if (cancel) {
        cancel();
      }
      this.store.app.confirmDialog.model = false;
    };
    this.store.app.confirmDialog.model = true;
  }

  getAuthLogin() {
    return this.store.app.authLogin;
  }

  getAuthPassword() {
    return this.store.app.authPassword;
  }

  setAuthLogin(v) {
    this.store.app.authLogin = v;
  }

  isDisabledAuth() {
    return !this.store.app.authLogin || !this.store.app.authPassword;
  }

  setAuthPassword(v) {
    this.store.app.authPassword = v;
  }

  async auth() {
    if (!this.store.app.authLogin) {
      this.snackError('You must enter login');
      return;
    }
    if (!this.store.app.authPassword) {
      this.snackError('You must enter password');
      return;
    }
    this.store.app.isAuthLoading = true;
    const data = await this.sendRequest('auth', {
      login: this.store.app.authLogin,
      password: this.store.app.authPassword,
    });
    this.store.app.isAuthLoading = false;
    this.store.app.authLogin = '';
    this.store.app.authPassword = '';
    if (!data || !data.token) {
      this.snackError('Incorrect login or password');
      return;
    }
    localStorage.setItem('onkitch-admin-tool-token', data.token);
    await this.router.push('/');
  }

  async logout() {
    this.openConfirmDialog({
      text: 'Are you sure you want to log out?',
      ok: async () => {
        localStorage.removeItem('onkitch-admin-tool-token');
        await this.router.push('/login');
      },
    });
  }

  getCurrentMail() {
    return this.store.app.currentMail;
  }

  setCurrentMail(v) {
    // TODO: do this in store
    // this.Vue.set(this.store.app, 'currentMail', v);
    this.store.app.currentMail = v;
  }

  isDisabledFindUser() {
    return !this.store.app.currentMail;
  }

  loadUser(userId) {
    if (this.store.app.currentUser && this.store.app.currentUser.id === userId) {
      return;
    }
    this.store.app.isCurrentUserLoading = true;
    this.sendRequest('user', {
      id: userId,
    }).then(async (data) => {
      this.store.app.isCurrentUserLoading = false;
      if (!data) {
        await this.router.push('/');
        this.snackError(`User does not exist with id ${userId}`);
        return;
      }
      this.store.app.currentUser = {
        ...data,
        created_at: this.formatDate(data.created_at),
      };
      await this.loadMenusForCurrentUser();
    });
  }

  async findUser() {
    this.store.app.isFindUserLoading = true;
    const data = await this.sendRequest('user', {
      email: this.store.app.currentMail.trim(),
    });
    this.store.app.isFindUserLoading = false;
    if (!data) {
      this.snackError(`User does not exist with email ${this.store.app.currentMail}`);
      return;
    }
    this.store.app.currentUser = {
      ...data,
      created_at: this.formatDate(data.created_at),
    };
    this.store.app.currentMail = '';
    await this.router.push({
      name: 'User',
      params: {
        id: this.store.app.currentUser.id,
      },
    });
    await this.loadMenusForCurrentUser();
  }

  async loadMenusForCurrentUser() {
    this.store.app.currentMenus = null;
    this.store.app.currentSpecialMenus = null;
    if (!this.store.app.currentUser) {
      throw new Error('Undefined current user');
    }
    if (!this.store.app.currentUser.id) {
      throw new Error('Undefined current user id');
    }
    this.store.app.isCurrentMenusLoading = true;
    try {
      this.store.app.currentMenus = (await this.sendRequest('user/menu', {
        userId: this.store.app.currentUser.id,
      }))
        .filter((el) => el.is_paid === 't')
        .map((el) => ({
          ...el,
          menuName: this.store.app.menus.find((m) => m.id === el.menu_id)?.name,
          created_at: this.formatDate(el.created_at),
          started_at: this.formatDate(el.started_at),
          finished_at: this.formatDate(el.finished_at),
        }));
    } catch (e) {
      console.error(e);
    } finally {
      this.store.app.isCurrentMenusLoading = false;
    }

    this.store.app.isCurrentSpecialMenusLoading = true;
    try {
      this.store.app.currentSpecialMenus = (await this.sendRequest('user/special-menu', {
        userId: this.store.app.currentUser.id,
      }))
        .filter((el) => el.is_paid === 't')
        .map((el) => ({
          ...el,
          menuName: this.store.app.menus.find((m) => m.id === el.recipe_set_id)?.name,
          created_at: this.formatDate(el.created_at),
        }));
    } catch (e) {
      console.error(e);
    } finally {
      this.store.app.isCurrentSpecialMenusLoading = false;
    }
  }

  getCurrentUser() {
    return this.store.app.currentUser;
  }

  getCurrentMenus() {
    return this.store.app.currentMenus;
  }

  getCurrentSpecialMenus() {
    return this.store.app.currentSpecialMenus;
  }

  geNotAddingMenus() {
    if (!this.store.app.currentMenus) {
      return null;
    }
    return this.store.app.menus.filter((el) => !el.isSpecial && !this.store.app.currentMenus.find((m) => m.menu_id === el.id));
  }

  geNotAddingSpecialMenus() {
    if (!this.store.app.currentMenus) {
      return null;
    }
    return this.store.app.menus.filter((el) => el.isSpecial && !this.store.app.currentSpecialMenus.find((m) => m.recipe_set_id === el.id));
  }

  async addMenu(id) {
    this.openConfirmDialog({
      text: 'Are you sure you want to add user to menu?',
      ok: async () => {
        this.store.app.addMenuRequestData = {
          userId: this.store.app.currentUser.id,
          menuId: id,
        };
        this.store.app.isAddMenuLoading = true;
        const r = await this.sendRequest('user/menu/add', this.store.app.addMenuRequestData);
        this.store.app.isAddMenuLoading = false;
        if (r) {
          this.snackSuccess('Adding menu succeed');
          await this.loadMenusForCurrentUser();
        } else {
          this.snackError('Adding menu failed');
        }
      },
    });
  }

  async addSpecialMenu(id) {
    this.openConfirmDialog({
      text: 'Are you sure you want to add user to special menu?',
      ok: async () => {
        this.store.app.addSpecialMenuRequestData = {
          userId: this.store.app.currentUser.id,
          menuId: id,
        };
        this.store.app.isAddSpecialMenuLoading = true;
        const r = await this.sendRequest('user/special-menu/add', this.store.app.addSpecialMenuRequestData);
        this.store.app.isAddSpecialMenuLoading = false;
        if (r) {
          this.snackSuccess('Adding special menu succeed');
          await this.loadMenusForCurrentUser();
        } else {
          this.snackError('Adding special menu failed');
        }
      },
    });
  }

  async removeMenu(id) {
    this.openConfirmDialog({
      text: 'Are you sure you want to remove user from menu?',
      ok: async () => {
        this.store.app.removeMenuRequestData = {
          userId: this.store.app.currentUser.id,
          menuId: id,
        };
        this.store.app.isRemoveMenuLoading = true;
        const r = await this.sendRequest('user/menu/remove', this.store.app.removeMenuRequestData);
        this.store.app.isRemoveMenuLoading = false;
        if (r) {
          this.snackSuccess('Removing menu succeed');
          await this.loadMenusForCurrentUser();
        } else {
          this.snackError('Removing menu failed');
        }
      },
    });
  }

  async removeSpecialMenu(id) {
    this.openConfirmDialog({
      text: 'Are you sure you want to remove user from special menu?',
      ok: async () => {
        this.store.app.removeSpecialMenuRequestData = {
          userId: this.store.app.currentUser.id,
          menuId: id,
        };
        this.store.app.isRemoveSpecialMenuLoading = true;
        const r = await this.sendRequest('user/special-menu/remove', this.store.app.removeSpecialMenuRequestData);
        this.store.app.isRemoveSpecialMenuLoading = false;
        if (r) {
          this.snackSuccess('Removing special menu succeed');
          await this.loadMenusForCurrentUser();
        } else {
          this.snackError('Removing special menu failed');
        }
      },
    });
  }

  async updatePassword(newPassword) {
    this.openConfirmDialog({
      text: 'Are you sure you want to update password?',
      ok: async () => {
        this.store.app.isUpdatePasswordLoading = true;
        const r = await this.sendRequest('user/profile/change-password', {
          userId: this.store.app.currentUser.id,
          password: newPassword,
        });
        this.store.app.isUpdatePasswordLoading = false;
        if (r) {
          this.snackSuccess('Updating password succeed');
        } else {
          this.snackError('Updating password failed');
        }
      },
    });
  }

  async openReport() {
    await this.router.push({
      name: 'Report',
    });
  }

  async openPromocoding() {
    await this.router.push({
      name: 'Promocoding',
    });
  }

  async openRecipes() {
    await this.router.push({
      name: 'Recipes',
    });
  }

  async generateReportExample() {
    const r = await this.sendRequest('reporting/google-sheet');
    if (r) {
      this.snackSuccess('Report generation succeed');
    } else {
      this.snackError('Report generation failed');
    }
  }

  async generateReportIngredients() {
    const data = await this.sendRequest('reporting/ingredients');
    if (data) {
      this.snackSuccess('Ingredients report generation succeed');
      this.store.app.reportIngredients = data;
    } else {
      this.snackError('Ingredients report generation failed');
    }
  }

  async generateReportRecipes() {
    const data = await this.sendRequest('reporting/recipes');
    if (data) {
      this.snackSuccess('Recipes report generation succeed');
      this.store.app.reportRecipes = data;
    } else {
      this.snackError('Recipes report generation failed');
    }
  }

  async generateReportPromocodes() {
    const data = await this.sendRequest('reporting/promocodes');
    if (data) {
      this.snackSuccess('Promocodes report generation succeed');
      this.store.app.reportPromocodes = data;
    } else {
      this.snackError('Promocodes report generation failed');
    }
  }

  async generateReportUsersPayments() {
    const data = await this.sendRequest('reporting/users-payments');
    if (data) {
      this.snackSuccess('Users payments report generation succeed');
      this.store.app.reportUsersPayments = data;
    } else {
      this.snackError('Users payments report generation failed');
    }
  }

  async getRecipesSets() {
    const data = await this.sendRequest('menu/recipes-sets');
    if (data) {
      this.store.app.menus = data.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      this.snackError('Getting recipes sets failed');
    }
  }

  async getPromocodes() {
    const data = await this.sendRequest('promocoding');
    if (data) {
      this.store.app.promocodes = data;
    } else {
      this.snackError('Getting promocodes failed');
    }
  }

  async addPromocode(d) {
    const data = await this.sendRequest('promocoding', d, 'post');
    if (data?.status !== 'ok') {
      this.snackError('Adding promocode failed');
      return;
    }
    this.snackSuccess('Adding promocode succeed');
    await this.getPromocodes();
  }

  async updatePromocode(d) {
    const data = await this.sendRequest('promocoding', d, 'put');
    if (data?.status !== 'ok') {
      this.snackError('Updating promocode failed');
      return;
    }
    this.snackSuccess('Updating promocode succeed');
    await this.getPromocodes();
  }

  async deletePromocode(d) {
    const data = await this.sendRequest('promocoding', d, 'delete');
    if (data?.status !== 'ok') {
      this.snackError('Deleting promocode failed');
      return;
    }
    this.snackSuccess('Deleting promocode succeed');
    await this.getPromocodes();
  }

  async getRecipes() {
    const data = await this.sendRequest('menu/recipes');
    if (data) {
      this.store.app.recipesData = data;
    } else {
      this.snackError('Getting recipes failed');
    }
  }

  async getRecipesWithProportions() {
    const data = await this.sendRequest('menu/recipes-with-proportions');
    if (data) {
      this.store.app.recipesData = data;
    } else {
      this.snackError('Getting recipes failed');
    }
  }
}
