<template>
  <div class="mx-auto" style="max-width: 400px;">
<!--    <span class="title">Reporting</span>-->
    <v-btn depressed block x-large
           @click="openReport"
           :loading="$store.AppStore.isOpenReportLoading"
    >Open reporting</v-btn>
  </div>
</template>

<script>
export default {
  name: 'OpenReport',
  methods: {
    openReport(...args) {
      return this.$api.main.openReport(...args);
    },
  },
};
</script>

<style scoped>

</style>
