<template>
  <v-col>
    <OpenUser class="block"/>
    <OpenRecipes class="block"/>
    <OpenReport class="block"/>
    <OpenPromocoding class="block"/>
  </v-col>
</template>

<script>
import OpenUser from '@/components/OpenUser';
import OpenReport from '@/components/OpenReport';
import OpenPromocoding from '@/components/OpenPromocoding';
import OpenRecipes from "@/components/OpenRecipes";

export default {
  name: 'Home',
  components: {
    OpenRecipes,
    OpenPromocoding,
    OpenReport,
    OpenUser,
  },
  computed: {
    isShowReporting() {
      return localStorage.getItem('isShowReporting') === 'true';
    },
  },
};
</script>

<style scoped>
.block:not(:first-child) {
  margin-top: 30px;
}

.block:not(:last-child) {
  margin-bottom: 30px;
}
</style>
